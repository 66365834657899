<template>
  <iframe id="versionIFrame" :src="iframeSrc" class="body" :style="{ minHeight: fullWidth + 'px' }" ref="myIframe"></iframe>
</template>

<script>
export default {
  data() {
    return {
      iframeSrc: '',
      fullWidth: null
    };
  },
  created() {
    var point = this.$route.hash;
    console.log('obj', process.env.VUE_APP_STATE);
    // console.log('this.$route.query.release', this.$route.query.release);
    if (this.$route.path === '/help/SyslabHelp.html') {
      this.iframeSrc = process.env.VUE_APP_STATE + '/docs/syslab/Help/homepage/index.html';
      // this.iframeSrc = 'http://127.0.0.1:8999/docs/syslab/Help/index.html';
      if (point) {
        // hash  路由hash存在，则追加到URL后面
        this.iframeSrc = process.env.VUE_APP_STATE + '/docs/syslab/Help/homepage/index.html' + point;
        // this.iframeSrc = 'http://127.0.0.1:8999/docs/syslab/Help/index.html' + point;
      }
      if (this.$route.query.release) {
        this.iframeSrc = process.env.VUE_APP_STATE + `/docs/syslab/Help/homepage/index.html#/Doc/Release_notes/${this.$route.query.release}.html`;
        // this.iframeSrc = `http://127.0.0.1:8999/docs/syslab/Help/index.html#/Doc/Release_notes/${this.$route.query.release}.html`;
      }
    } else if (this.$route.path === '/help/SysplorerHelp.html') {
      // this.iframeSrc = 'http://127.0.0.1:8999/docs/sysplorer/Help/index.html';
      this.iframeSrc = process.env.VUE_APP_STATE + '/docs/sysplorer/Help/index.html';
      if (point) {
        this.iframeSrc = process.env.VUE_APP_STATE + '/docs/sysplorer/Help/index.html' + point;
        // this.iframeSrc = 'http://127.0.0.1:8999/docs/sysplorer/Help/index.html' + point;
      }
      if (this.$route.query.release) {
        this.iframeSrc = process.env.VUE_APP_STATE + `/docs/sysplorer/Help/index.html#/Doc/WhatsNew/${this.$route.query.release}.html`;
        // this.iframeSrc = `http://127.0.0.1:8999/docs/sysplorer/Help/index.html#/Doc/WhatsNew/${this.$route.query.release}.html`;
      }
    }
    this.fullWidth = window.innerHeight - 114;
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    // 监听消息
    window.addEventListener('message', this.handleIframeMessage);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('message', this.handleIframeMessage);
  },
  computed: {
    minHeight() {
      return window.innerHeight - 114;
    }
  },
  methods: {
    handleResize(event) {
      this.fullWidth = window.innerHeight - 114;
    },
    handleIframeMessage(event) {
      // if (event.origin !== 'https://www.tongyuan.cc') { // 根据实际情况校验发送消息的源地址安全性
      //   return;
      // }
      let newRoute = '';
      console.log('eventtttttt', event.origin); // http://127.0.0.1:8999
      const message = event.data;
      const currentRoute = this.$route.fullPath; // 获取当前完整的路由路径  /help/SysplorerHelp.html
      if (typeof message === 'object' && message.fullPath) {
        // newRoute = currentRoute + '#' + message.fullPath;
        if (currentRoute.includes('/help/SysplorerHelp.html')) {
          newRoute = '/help/SysplorerHelp.html#' + message.fullPath;
        } else if (currentRoute.includes('/help/SyslabHelp.html')) {
          newRoute = '/help/SyslabHelp.html#' + message.fullPath;
        }
        // 使用 history API 更新浏览器 URL 而不触发 Vue Router 的导航
        console.log('newRoute', newRoute);
        window.history.replaceState({}, '', newRoute);
      }
    }

  }
};
</script>

<style lang="scss" scoped>
.body {
  background-size: 100% 100%;
  width: 100%;
  border: none;
  overflow: scroll;
  overflow-y: hidden;
}
</style>
